<template>
  <v-col>
    <div class="add--vehicle tw-mb-6">
      <div class="tw-flex tw-w-full tw-justify-end">
        <v-btn right class="primary" id="addVehicle" @click="addVehicle">
          <v-icon>mdi-plus</v-icon>Add Vehicle
        </v-btn>
      </div>
      <v-dialog v-model="dialog" width="400px">
        <v-card>
          <v-container class="text-center">
            <v-img
              src="@/assets/img/vehicle-search.png"
              class="mx-auto my-5"
              width="175"
            />
            <v-card-title>
              <h3 class="mx-auto primary--text">Vehicle Verification Check</h3>
            </v-card-title>
            <v-card-text>
              <p>
                Please check whether this vehicle qualifies for
                <br />assessment, input the Registration No.
              </p>
            </v-card-text>
            <v-card-actions class="vehicle--search">
              <form
                class="mx-auto mb-5 vehicle--search__input"
                @submit.prevent="submit"
              >
                <v-text-field
                  id="vehicleRegNoInput"
                  v-model="regNo"
                  type="text"
                  label="Vehicle Reg No."
                  placeholder="e.g KBZ 453Z"
                  prepend-icon="mdi-car"
                  required
                ></v-text-field>
                <v-btn
                  id="vehicleCheckerBtn"
                  class="secondary my-5"
                  block
                  large
                  :loading="loading"
                  @click="vehicleChecker"
                >
                  Process
                </v-btn>
              </form>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>

      <app-book-vehicle
        @updateDialogVehicleStatus="updateDialogVehicleStatus"
        :dialogVehicle="dialogVehicle"
        :vehicleDetails="vehicleDetails"
      />
      <app-expired-policy
        :dialogExpired="dialogExpired"
        @updateExpiredDialogStatus="updateExpiredDialogStatus"
      />
      <app-third-party-policy
        :dialogThirdParty="dialogThirdParty"
        @updateThirdPartyDialogStatus="updateThirdPartyDialogStatus"
      />
    </div>
  </v-col>
</template>

<script>
/* eslint-disable no-unused-vars */
import validation from '@/utils/Validations'
import axios from 'axios'

export default {
  components: {
    appBookVehicle: () =>
      import(
        '@/components/assessment-center/booking/vehicle-status/verifiedVehicle'
      ),
    appExpiredPolicy: () =>
      import(
        '@/components/assessment-center/booking/vehicle-status/expiredPolicy'
      ),
    appThirdPartyPolicy: () =>
      import(
        '@/components/assessment-center/booking/vehicle-status/thirdPartyPolicy'
      ),
  },

  data: () => ({
    valid: false,
    regNo: 'KCG 006U',
    dialog: false,
    dialogInvite: false,
    loading: false,
    dialogVehicle: false,
    dialogExpired: false,
    dialogThirdParty: false,
    vehicleDetails: {},
    ...validation,
  }),

  methods: {
    addVehicle() {
      this.dialog = true
      this.dialogVehicle = false
      this.dialogExpired = false
      this.dialogThirdParty = false
      this.loading = false
    },
    updateDialogVehicleStatus: function (status) {
      this.dialogVehicle = status
    },
    vehicleChecker() {
      this.loading = true
      const myRegNo = this.regNo

      axios
        .get(
          `/motor-assessment/api/vehicle/verify?registrationNumber=${myRegNo}`
        )
        .then((res) => {
          this.dialog = false

          if (
            res.data.success === true &&
            res.data.data.policy.coverType === 'Comprehensive Motor Insurance'
          ) {
            this.dialogVehicle = true
            this.vehicleDetails = res.data.data
          } else if (
            res.data.success === true &&
            res.data.data.policy.coverType === 'Third Party Motor Insurance'
          ) {
            this.dialogThirdParty = true
          } else {
            this.dialogExpired = true
          }
        })
        .catch(() => {
          this.loading = true
        })
    },
    updateExpiredDialogStatus: function (status) {
      this.dialogExpired = status
    },
    updateThirdPartyDialogStatus: function (status) {
      this.dialogThirdParty = status
    },
  },
}
</script>
<style scoped>
.add--vehicle {
  height: 0px;
  float: right;
}

.vehicle--search__input {
  width: 300px;
}
</style>
